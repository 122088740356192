import { HttpParams } from '@angular/common/http';
import { CustomURLEncoder } from '../shared/helpers/custom-url-encoder';
import { Utm } from '../dataset/Utm';

export class Api {
  protected params(object: object): HttpParams {
    let parameters = new HttpParams({
      encoder: new CustomURLEncoder(),
    });
    Object.entries(object).forEach(([key, value]) => {
      if (String(value) !== 'undefined') {
        parameters = parameters.append(key, String(value));
      }
    });
    return parameters;
  }

  protected setUtmTagsToHttpParams(utm?: Utm): HttpParams {
    const queryParams = new HttpParams();

    return queryParams
      .set('utm_source', utm?.utm_source ?? '')
      .set('utm_medium', utm?.utm_medium ?? '')
      .set('utm_campaign', utm?.utm_campaign ?? '')
      .set('utm_term', utm?.utm_term ?? '')
      .set('utm_content', utm?.utm_content ?? '');
  }
}
